import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Link from "../common/Link"

export default function PortfolioGridItem({ item }) {
  const { portfolioItem, uri, title, slug } = item
  const { imagesPortfolioItem } = portfolioItem

  const img =
    imagesPortfolioItem[0].imagePortfolioItem.localFile.childImageSharp
      .gatsbyImageData
  return (
    <Link to={uri}>
      <div className="group overflow-hidden relative">
        <div className="relative">
          <div className="relative">
            <GatsbyImage image={getImage(img)} className="" />
          </div>
          <div className="flex flex-col items-center justify-center text-center absolute z-20 w-full h-full top-0  bg-opacity-0 lg:group-hover:bg-opacity-60 transition-all group-hover:bg-black  p-8">
            <h3 className="text-white relative z-30 opacity-0  lg:group-hover:opacity-100 transition-all">
              {title}
            </h3>
          </div>
        </div>
      </div>
    </Link>
  )
}
