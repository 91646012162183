import React from "react"

import Layout from "../components/layout"
import PortfolioPage from "../components/portfolio/PortfolioPage"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Work" />

    <PortfolioPage />
  </Layout>
)

export default IndexPage
