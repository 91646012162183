import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PortfolioGridItem from "./PortfolioGridItem"
import Link from "../common/Link"
import PageHeader from "../common/PageHeader"

export default function PortfolioList() {
  const data = useStaticQuery(graphql`
    query {
      allWpPortfolioCategory(sort: { fields: count, order: DESC }) {
        nodes {
          slug
          name
        }
      }
      allWpPortfolioItem {
        nodes {
          title
          uri
          slug
          portfolioCategories {
            nodes {
              slug
            }
          }
          portfolioItem {
            imagesPortfolioItem {
              imagePortfolioItem {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: DOMINANT_COLOR
                      aspectRatio: 1.33
                      layout: CONSTRAINED
                      transformOptions: { cropFocus: CENTER }
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const items = data.allWpPortfolioItem.nodes
  const categories = data.allWpPortfolioCategory.nodes
  const [activeCategory, setActiveCategory] = React.useState("all")

  const handleCategoryClick = category => {
    setActiveCategory(category)
  }

  console.log(categories)
  const all = [{ slug: "all", name: "All" }]
  const allCategories = all.concat(categories)
  console.log(allCategories)
  return (
    <div className="wp-page  min-h-screen h-full">
      <div className="px-4 lg:px-8 space-y-4  py-4 lg:py-16 max-w-screen-2xl mx-auto">
        <PageHeader text="Work" />
        <div className="flex flex-row flex-wrap gap-y-2 gap-x-6 lg:gap-4 text-sm lg:text-xl ">
          {/* <button onClick={() => handleCategoryClick("all")}>All</button> */}

          {allCategories.map(category => {
            return (
              <button
                key={category.slug}
                onClick={() => handleCategoryClick(category.slug)}
                className={`${
                  activeCategory === category.slug
                    ? "underline decoration-third underline-offset-4"
                    : ""
                } `}
              >
                {category.name}
              </button>
            )
          })}
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
          {activeCategory === "all"
            ? items.map(item => {
                return <PortfolioGridItem key={item.id} item={item} />
              })
            : items
                .filter(item =>
                  item.portfolioCategories.nodes.some(
                    x => x.slug === activeCategory
                  )
                )
                .map(item => {
                  return <PortfolioGridItem key={item.id} item={item} />
                })}
        </div>
      </div>
    </div>
  )
}
